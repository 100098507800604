<template>
  <div id="PasswordReset">
    <!-- <TheHeader></TheHeader>
    <SiteNav v-if="user"></SiteNav> -->
    <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-lines bg-cover bg-center">
    <div class="max-w-md w-full space-y-8">
      <img class="mx-auto h-28 w-auto" src="@/assets/logo_border.png" alt="Workflow" />
      <div class="bg-gray-800 rounded-lg mt-6 pb-3 p-3">
        <div class="text-shadow">
          <h2 class="mt-4 text-center text-3xl font-extrabold text-white">
            Passwort zurücksetzen
          </h2>
          <p class="text-center"><a class="link text-sm" href="https://hilfe.stadtbahnfahrer.club" target="_newTab">Benötigst Du Hilfe? Kontaktiere uns gerne.</a></p>
        </div>
        <form v-on:submit.prevent v-show="!isSuccess && this.$route.query.token" class="mt-8 space-y-6" action="#" method="POST">
          <div class="rounded-md shadow-sm -space-y-px text-center">
            <div>
              <h4 class="mt-6  font-extrabold text-white text-shadow">
                Neues Passwort
              </h4>
              <label for="password" class="sr-only">Neues Passwort</label>
              <input @input="checkInputs" id="password" name="password" type="password" autocomplete="new-password" required v-model.trim="password" :disabled="isLoading" class="text-center appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Neues Passwort" />
            </div>
            <div>
              <h4 class="mt-6  font-extrabold text-white text-shadow">
                Neues Passwort wiederholen
              </h4>
              <label for="passwort-repeat" class="sr-only">Neues Passwort wiederholen</label>
              <input @input="checkInputs" id="passwort-repeat" name="password-repeat" type="password" autocomplete="new-password" required v-model.trim="passwordRepeat" :disabled="isLoading" class="text-center appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Neues Passwort wiederholen" />
            </div>
          </div>

          <div>
            <button :disabled="isLoading || stepCompleteDisabled" @click="submit" type="submit" class="button-filled group relative w-full flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white hover:bg-blue-400">
              Neues Passwort speichern
            </button>
          </div>
        </form>
        <div v-show="isSuccess  && this.$route.query.token">
          <p class="text-center mt-3">Wir haben Dein Passwort erfolgreich gespeichert! Du kannst Dich nun mit Deinem neuen Passwort <a href="/anmelden" class="link">anmelden</a>.</p>
        </div>
        <div v-show="!this.$route.query.token">
          <p class="text-center mt-3">Der Link ist ungültig. {{this.$route.query.token}}</p>
        </div>
      </div>
    </div>
  </div>
  </div> 
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import { mapGetters } from "vuex";
import TheHeader from "@/components/TheHeader";

export default {
  name: "Login",
  components: {
    TheHeader,
    SiteNav,
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      password: "",
      passwordRepeat: "",
      stepCompleteDisabled: true
    };
  },
  methods: {
    checkInputs() {
      if(
      this.password.length > 6 &&
      this.password == this.passwordRepeat) {
        this.stepCompleteDisabled = false
      } else {
        this.stepCompleteDisabled = true
      }
    },
    submit() {
      if(this.password != this.passwordRepeat) {
        alert('Die Passwörter sind nicht identisch.');
        return
      }

      this.isLoading = true;

      this.$store
        .dispatch("setNewPasswordWithResetToken", {"password":this.password,"token":this.$route.query.token})
        .then((a) => {
          this.isSuccess = true;

          this.stepCompleteDisabled = true

          this.isLoading = false;
        });
    },
  },
  // mounted() {
  //   this.$store.dispatch("fetchUserProfile");
  // },
  // computed: {
  //   ...mapGetters({
  //     user: "getUser",
  //   }),
  // },
};
</script>

<style scoped>
.badge {
  width: 220px;
  margin: auto;
}

.lol {
  max-width: 400px;
}
</style>